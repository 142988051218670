f<template lang="pug">
  .current-fight
    h1 Lutando Agora
    .current-fight__robots
      .robot
        Icon(
          :file="takeBlueRobot.image"
          :icon="['fas', 'robot']"
          border
          borderColor="blue"
          :label="takeBlueRobot.name"
        )

        span.count(
          v-if="getUser.roles.includes(3) && getUser.id !== takeJudgeId && step === 1"
        ) Agressividade: {{aggressivityBlue}}

        Select(
          v-if="getUser.roles.includes(3) && getUser.id !== takeJudgeId && step === 1"
          defaultText="Selecionar Dano"
          :options="takeDamageOptions"
          type='drop'
          @emit="doSetBlueDamage"
        )

      .robot.robot__info
        span {{takeCategory}}
        .button
          Button(
            :text="takeButtonText"
            size="big"
            @click="doSetAction"
          )
        span.robot.robot__info.robot__info--error(
          v-if="showErrorCount && this.step === 0"
        ) Empate, adicione um ponto à um dos robôs para desempatar
        span.robot.robot__info.robot__info--error(
          v-if="showErrorJudges && this.step === 0"
        ) Nem todos os juízes finalizaram a contagem

      .robot
        Icon(
          :file="takeRedRobot.image"
          :icon="['fas', 'robot']"
          label="Nome"
          border
          borderColor="red"
          :label="takeRedRobot.name"
        )

        span.count(
          v-if="getUser.roles.includes(3) && getUser.id !== takeJudgeId && step === 1"
          ) Agressividade: {{aggressivityRed}}

        Select(
          v-if="getUser.roles.includes(3) && getUser.id !== takeJudgeId && step === 1"
          defaultText="Selecionar Dano"
          :options="takeDamageOptions"
          type='drop'
          @emit="doSetRedDamage"
        )

    .current-fight__counters(
      v-if="getUser.roles.includes(3) && getUser.id !== takeJudgeId && step === 0"
    )
      .counter.counter--blue(
        @click="doUpBlue"
      )
        span {{aggressivityBlue}}
      .counter.counter--red(
        @click="doUpRed"
      )
        span {{aggressivityRed}}

    .current-fight__scores(
      v-if="getUser.roles.includes(4) && getUser.id === takeJudgeId && step === 1"
    )
      .score(
        v-if="takeDamages"
        v-for="judge in takeDamages"
      )
        span.judgeName Juiz {{judge.judge_name}}
        span.value Dano Robô Azul: {{judge.blue_damage_scores}}
        span.value Agressividade Robô Azul: {{judge.blue_aggressivity_scores}}
        span.value Dano Robô Vermelho: {{judge.red_damage_scores}}
        span.value Agressividade Robô Vermelho: {{judge.red_aggressivity_scores}}

    .current-fight__scores.current-fight__scores--column(
      v-if="getUser.roles.includes(4) && getUser.id === takeJudgeId && step === 1"
    )
      span.value(
        v-if="takeFinalScore"
        :class="[...takeWinnerModifier.blue]"
        ) Dano Total Azul {{takeBlueRobot.name}}: {{takeFinalScore.blue_sum}}
      span.value(
        v-if="takeFinalScore"
        :class="[...takeWinnerModifier.red]"
        ) Dano Total Vermelho {{takeRedRobot.name}}:{{takeFinalScore.red_sum}}
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  Icon,
  Button,
  Select
} from '@/components/atoms'

export default {
  name: 'CurrentFight',
  data: () => ({
    aggressivityBlue: 0,
    aggressivityRed: 0,
    damageBlue: 0,
    damageRed: 0,
    step: 0,
    showErrorCount: false,
    showErrorJudges: false
  }),
  components: {
    Icon,
    Button,
    Select
  },
  mounted () {},
  beforeDestroy () {
    this.setCleanCurrentFight()
  },
  computed: {
    ...mapGetters('users', [
      'getUser'
    ]),
    ...mapGetters('fights', [
      'getCurrentFight'
    ]),
    takeJudgeId () {
      if (this.getCurrentFight.length) {
        return this.getCurrentFight[0].round_judge_id
      }
      return -1
    },
    takeBlueRobot () {
      if (this.getCurrentFight.length) {
        return this.getCurrentFight.find(robot => robot.id && robot.name && !robot.is_red_side)
      }
      return { name: '' }
    },
    takeRedRobot () {
      if (this.getCurrentFight.length) {
        return this.getCurrentFight.find(robot => robot.id && robot.name && robot.is_red_side)
      }
      return { name: '' }
    },
    takeCategory () {
      if (this.getCurrentFight.length) {
        return this.getCurrentFight.find(obj => obj.category_name).category_name
      }
      return ''
    },
    takeButtonText () {
      if (this.getUser.roles.includes(4) && this.judgeId && this.judgeId === this.getUser.id) {
        return 'Finalizar julgamento' // juiz de round
      } else if (this.getUser.roles.includes(4) && this.judgeId && this.judgeId !== this.getUser.id) {
        return 'Próximo' // juiz de round
      } else if (this.getUser.roles.includes(3) && this.step === 0) {
        return 'Próximo' // juiz de combate
      }
      return 'Finalizar'
    },
    takeDamageOptions () {
      const arr = []
      for (let i = 0; i <= 6; i++) {
        arr.push({ text: i.toString(), value: i })
      }
      return arr
    },
    takeDamages () {
      let arr = []
      if (this.getCurrentFight.length === 4) {
        const judges = this.getCurrentFight.find(obj => obj.firstJudge)
        arr = [
          judges.firstJudge,
          judges.secondJudge,
          judges.thirdJudge
        ]
      }
      return arr
    },
    takeFinalScore () {
      let scores = {}
      if (this.getCurrentFight.length === 4) {
        scores = this.getCurrentFight.find(obj => obj.firstJudge).scores
      }
      return scores
    },
    takeWinnerModifier () {
      if (this.takeFinalScore.blue_sum > this.takeFinalScore.red_sum) {
        return { red: [], blue: ['value--winner'] }
      }
      return { red: ['value--winner'], blue: [] }
    }
  },
  methods: {
    ...mapActions('fights',
      [
        'setCleanCurrentFight',
        'setfightAggressivity',
        'setFightDamage',
        'setFinishFight',
        'setInfoJudgeScoreFight'
      ]),
    doUpRed () {
      this.aggressivityRed = this.aggressivityRed + 1
    },
    doSetRedDamage (e) {
      this.damageRed = e
    },
    doUpBlue () {
      this.aggressivityBlue = this.aggressivityBlue + 1
    },
    doSetBlueDamage (e) {
      this.damageBlue = e
    },
    doSetAction () {
      let fight = this.getCurrentFight
      const id = this.getCurrentFight.find(obj => obj.id).id
      const judgeId = this.getCurrentFight.find(obj => obj.round_judge_id).round_judge_id
      if (this.step === 0) {
        if (judgeId === this.getUser.id) {
          this.setInfoJudgeScoreFight({ fight_id: id }).then(info => {
            fight = this.getCurrentFight
            if (fight.find(obj => obj.firstJudge)) {
              const obj = fight.find(obj => obj.firstJudge)
              if (obj.firstJudge && obj.secondJudge && obj.thirdJudge) {
                this.step = this.step + 1
              }
            } else {
              this.showErrorJudges = true
            }
          })
        } else {
          if (this.aggressivityRed === this.aggressivityBlue) {
            this.showErrorCount = true
            return
          }
          this.setfightAggressivity({
            judge_id: this.getUser.id,
            fight_id: id,
            red_id: fight.find(fight => fight.name && fight.is_red_side).id,
            blue_id: fight.find(fight => fight.name && !fight.is_red_side).id,
            red_hits: this.aggressivityRed,
            blue_hits: this.aggressivityBlue
          })
          this.step = this.step + 1
        }
        return
      }

      if (judgeId === this.getUser.id) {
        this.setFinishFight({
          fight_id: id
        }).then(res =>
          this.$router.push('Fights')
        )
      } else {
        this.setFightDamage({
          judge_id: this.getUser.id,
          fight_id: id,
          red_id: fight.find(fight => fight.name && fight.is_red_side).id,
          blue_id: fight.find(fight => fight.name && !fight.is_red_side).id,
          red_damage: this.damageRed.value,
          blue_damage: this.damageBlue.value
        }).then(res =>
          this.$router.push('Fights')
        )
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .current-fight
    letter-spacing: 0em
    height: 100%
    .button
      display: flex
      justify-content: center
    &__counters
      display: flex
      .counter
        min-height: 240px
        display: flex
        width: 100%
        height: 100%
        margin: 8px
        align-items: center
        justify-content: center
        font-size: 32px
        font-weight: 700
        line-height: 108px
        letter-spacing: 0em
        color: #fff
        cursor: pointer
        &--blue
          background: #283CED
        &--red
          background: #ED2828

    &__robots
      display: flex
      flex-direction: row
      justify-content: space-between
      margin: 64px
      @media (max-width: 600px)
        display: flex
        flex-direction: column
      .robot
        display: flex
        flex-direction: column
        align-items: center
        min-width: 166px
        .count
          margin: 8px 0
          font-size: 20px
          font-weight: 700
          line-height: 72px

        &__info
          margin: 36px
          font-size: 24px
          font-weight: 700
          line-height: 53px
          text-align: center
          &--error
            font-size: 16px
            color: red
    &__scores
      display: flex
      justify-content: space-between
      margin: 64px
      &--column
        flex-direction: column
        justify-content: center
        align-items: center
      .score
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        .judgeName
          font-size: 20px
          font-weight: 500
          margin: 16px
      .value
        font-size: 20px
        margin: 4px
        &--winner
          font-size: 24px
          font-weight: 600

</style>
